.home {
  &__container {
    position: relative;

    .docks__wrapper {
      position: relative;
      width: 100%;
      margin: 7.5px 0;
      height: calc(100% - 20px);
    }
    .disconnect-fade {
      background-color: $grey-opacity;
      cursor: wait;
    }
  }
}

#cancel-drag-area {
  position: fixed;
  width: 3rem;
  height: 3rem;
  bottom: 26.25px;
  left: 26.25px;
  text-align: center;
  z-index: 999;

  .oi {
    font-size: $font-size-headline3;
  }
}

.watermark {
  margin: auto;
  position: absolute;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 0;
}

.banner-reduced {
  height: calc(100% - 45px);
  width: 100%;
  bottom: 0;
  position: absolute;
}
.banner {
  height: auto;
  width: 100%;
  top: 0;
  position: absolute;
}

.bannerImage {
  height: 40px;
  width: auto;
  padding: inherit;
  margin: auto 0;
  display: block;
}